<template>
  <div>
    <iframe :src="url" id="mobsf" frameborder="0"></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: this.$store.state.data_gear
    };
  },
  mounted() {
    /**
     * iframe-宽高自适应显示
     */
    function changeMobsfIframe() {
      const mobsf = document.getElementById("mobsf");
      const deviceWidth = document.body.clientWidth;
      const deviceHeight = document.body.clientHeight;
      mobsf.style.width = Number(deviceWidth) - 120 + "px"; //数字是页面布局宽度差值
      mobsf.style.height = Number(deviceHeight) - 160 + "px"; //数字是页面布局高度差
    }
    changeMobsfIframe();
    window.onresize = function () {
      changeMobsfIframe();
    };
  },
  created() {
    // 监听iframe的数据
    window.addEventListener("message", this.receiveMessage, false);
  },
  destroyed() {
    window.removeEventListener("message", this.receiveMessage);
  },
  methods: {
    receiveMessage(event) {
      let data = JSON.parse(event.data);
      this.postRequest("/dashBoard/insertDashBoard", data);
    },
  },
};
</script>
